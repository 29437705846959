<template>
	<main class="main-page">
		<template v-if="showHeader">
			<section class="page-section">
				<div class="container">
					<div class="grid mb-3 card nice-shadow-6 justify-content-between align-items-center">
						<div class="col ">
							<div class="">
								<div class="flex align-items-center ">
									<div class="mr-3">
										<Button @click="$router.go(-1)" label="" icon="pi pi-arrow-left"
                                        class="p-button-sm p-button-rounded px-4" />
									</div>
									<div>
										<div class="text-2xl text-primary font-bold">User Accounts</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 md:col-3 ">
							<Button label="Add New User Account"
								@click="app.openPageDialog({ page: 'users/add', url: `/users/add` , persistent: true,closeBtn: true })"
								class="p-button w-full bg-primary " />
						</div>
						<div class="col-12 md:col-5 lg:col-4 ">
							<span class="p-input-icon-left w-full">
								<i class="pi pi-search" />
								<InputText placeholder="Search" class="w-full" :value="searchText"
									@input="debounce(() => { searchText = $event.target.value })" />
							</span>
						</div>
					</div>
				</div>
			</section>
		</template>
		<section class="page-section ">
			<div class="container">
				<div class="grid ">
					<div class="col comp-grid">
						<div class="flex align-items-center justify-content-around">
							<div v-if="searchText" :class="filterTagClass">
								Search
								<Chip class="font-medium px-2 py-1" removable @remove="clearSearch()">{{ searchText }}
								</Chip>
							</div>
						</div>
						<div>
							<template v-if="showBreadcrumbs && $route.query.tag">
								<Breadcrumb :home="{icon: 'pi pi-home', to: '/users'}" :model="pageBreadCrumb" />
							</template>
							<!-- page records template -->
							<div class="page-records" v-if="!loading">
								<DataTable :lazy="true" :loading="loading" :value="records" dataKey="user_id"
									@sort="onSort($event)" class="" :showGridlines="false" :rowHover="true"
									responsiveLayout="stack">
									<Column field="photo" header="Photo">
										<template #body="{data}">
											<image-viewer image-size="medium" image-preview-size="" :src="data.photo"
												width="30px" height="30px" :num-display="1">
											</image-viewer>
										</template>
									</Column>
									<Column field="user_type" header="Type">
										<template #body="{data}">
											{{ data.user_type }}
										</template>
									</Column>
									<Column field="full_name" header="Full Name">
										<template #body="{data}">
											{{ data.full_name }}
										</template>
									</Column>
									<Column field="email" header="Email">
										<template #body="{data}">
											<a class="p-button-text" :href="'mailto:' + data.email">{{ data.email }}</a>
										</template>
									</Column>
									<Column field="mobile_number" header="Mobile Number">
										<template #body="{data}">
											{{ data.mobile_number }}
										</template>
									</Column>
									<Column field="date_created" header="Date Created" :sortable="true">
										<template #body="{data}">
											{{$utils.humanDatetime( data.date_created )}}
										</template>
									</Column>
									<Column headerStyle="width: 3em" headerClass="text-center">
										<template #body="{data}">
											<SplitButton class="p-button p-button-text p-button-sm"
												:model="getActionMenuModel(data)">
												<i></i>
											</SplitButton>
										</template>
									</Column>
								</DataTable>
							</div>
							<!-- page loading indicator -->
							<template v-if="loading">
								<div>
									<DataTable :value="[1,2,3,4,5]" responsiveLayout="scroll">
										<Column style="width:3rem">
											<template #body>
												<Skeleton size="2rem" />
											</template>
										</Column>
										<Column>
											<template #body>
												<Skeleton />
											</template>
										</Column>
										<Column style="width:3rem">
											<template #body>
												<Skeleton size="2rem" />
											</template>
										</Column>
									</DataTable>
								</div>
							</template>
							<!-- end of page loading indicator-->
							<!-- Empty record -->
							<template v-if="pageReady && !records.length">
								<div class="p-3 my-3 text-500 text-lg font-medium text-center">
									No record found
								</div>
							</template>
							<!-- end of empty record-->
							<!-- pagination component-->
							<template v-if="showFooter">
								<div class="">
									<div v-show="pageReady">
										<div class="flex justify-content-between">
											<div v-if="paginate && totalPages > 1" class="flex-grow-1">
												<Paginator class="border-none bg-transparent py-3"
													:first="recordsPosition"
													@page="(event)=>{pagination.page = event.page + 1}"
													:rows="pagination.limit" :totalRecords="totalRecords">
													<template #start>
														<span class="text-sm text-gray-500 px-2">
															Records <b>{{ recordsPosition }} of {{ totalRecords }}</b>
														</span>
													</template>
													<template #end>
													</template>
												</Paginator>
											</div>
											<div class="flex justify-content-center flex-grow-0">
												<div v-if="selectedItems.length" class="m-2">
													<Button @click="deleteItem(selectedItems)" icon="pi pi-trash"
														class="p-button-danger" title="Delete Selected" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</template>
							<!-- end of pagination component-->
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>
<script setup>
	import { reactive, toRefs, onMounted } from 'vue';
	import { utils } from '@/utils';
	import { useApp } from '@/composables/app.js';
	import { useListPage } from '@/composables/listpage.js';
	const props = defineProps({
		primaryKey: {
			type: String,
			default: 'user_id',
		},
		pageName: {
			type: String,
			default: 'users',
		},
		routeName: {
			type: String,
			default: 'userslist',
		},
		apiPath: {
			type: String,
			default: 'users/index',
		},
		paginate: {
			type: Boolean,
			default: true,
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		showBreadcrumbs: {
			type: Boolean,
			default: true,
		},
		exportButton: {
			type: Boolean,
			default: true,
		},
		importButton: {
			type: Boolean,
			default: false,
		},
		multiCheckbox: {
			type: Boolean,
			default: false,
		},
		emptyRecordMsg: {
			type: String,
			default: "No record found",
		},
		titleBeforeDelete: {
			type: String,
			default: "Delete record",
		},
		msgBeforeDelete: {
			type: String,
			default: "Are you sure you want to delete this record?",
		},
		msgAfterDelete: {
			type: String,
			default: "Record deleted successfully",
		},
		page: {
			type: Number,
			default: 1,
		},
		limit: {
			type: Number,
			default: 15,
		},
		mergeRecords: { // for infinite loading
			type: Boolean,
			default: false,
		},
		search: {
			type: String,
			default: '',
		},
		fieldName: null,
		fieldValue: null,
		sortBy: {
			type: String,
			default: '',
		},
		sortType: {
			type: String,
			default: '', //desc or asc
		},
		isSubPage: {
			type: Boolean,
			default: false,
		},
		filterTagClass: {
			type: String,
			default: 'surface-card p-2 text-500 flex-grow-1 text-center m-1 mb-3 flex-grow-1 text-center',
		}
	});
	const app = useApp();
	const filters = reactive({
	});
	// init list page hook
	const page = useListPage(props, filters);
	const { state, methodsProps } = page;
	const {
		totalRecords,
		recordCount,
		loading,
		selectedItems,
		pagination,
		searchText,
		pageReady
	} = toRefs(page.state);
	const {
		records,
		apiUrl,
		currentRecord,
		pageBreadCrumb,
		canLoadMore,
		finishedLoading,
		totalPages,
		recordsPosition
	} = page.computedProps;
	const {
		load,
		reload,
		loadNextPage,
		loadPreviousPage,
		exportPage,
		clearSearch,
		onSort,
		debounce,
		deleteItem,
		setCurrentRecord,
		isCurrentRecord,
		removeFilter,
		getFilterLabel,
		filterHasValue,
		importComplete
	} = page.methods;
	function getActionMenuModel(data) {
		return [
			{
				label: "View",
				command: (event) => { app.openPageDialog({ page: 'users/view', url: `/users/view/${data.user_id}`, persistent: true, closeBtn: true }) },
				icon: "pi pi-eye"
			},
			{
				label: "Edit",
				command: (event) => { app.openPageDialog({ page: 'users/edit', url: `/users/edit/${data.user_id}`, persistent: true, closeBtn: true }) },
				icon: "pi pi-pencil"
			},
			{
				label: "Delete",
				command: (event) => { deleteItem(data.user_id) },
				icon: "pi pi-minus-circle"
			}
		]
	}
	onMounted(() => {
		const pageTitle = "User Accounts";
		app.setPageTitle(props.routeName, pageTitle);
		load();
	});
</script>
<style scoped>
</style>